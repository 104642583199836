/***
 *
 *   MODAL
 *   Display an overlay modal anywhere in your application by calling
 *   context.modal.show() with an object containing the following props
 *
 *   PROPS
 *   title: (optional)
 *   text: message to the user (optional)
 *   form: a form object (optional: see form docs for more information)
 *   url: destination to send the form
 *   method: HTTP post type
 *   buttonText – text for the confirm button
 *
 **********/

import { useContext } from 'react'
import { ViewContext, Card, Form } from 'components/lib'
import { CSSTransition } from 'react-transition-group'
import { Button } from '@material-tailwind/react'
import './modal.scss'
import { useEffect } from 'react'

export function Modal (props) {
  const context = useContext(ViewContext)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <CSSTransition in appear timeout={0} classNames='modal'>
      <div
        className='modal'
        onClick={e => e.target === e.currentTarget && context.modal.hide(true)}
      >
        <div className='modal-content'>
          <Card title={props.title}>
            {props.content && props.content()}

            {props.text && <p>{props.text}</p>}

            {props.form && (
              <Form
                method={props.method}
                url={props.url}
                inputs={props.form}
                destructive={props.destructive}
                buttonText={props.buttonText}
                cancelButtonText={props.cancelButtonText || 'Cancel'}
                inputsClassName={props.inputsClassName}
                cancel={e => context.modal.hide(true)}
              />
            )}

            {props.onlyActions && (
              <div className='flex gap-4'>
                <Button
                  color='green'
                  onClick={() => {
                    props.takeAction()
                    context.modal.hide(true)
                  }}
                >
                  {props.buttonText}
                </Button>
                <Button
                  variant={`outlined`}
                  onClick={() => context.modal.hide(true)}
                >
                  {props.cancelButtonText || 'Cancel'}
                </Button>
              </div>
            )}
          </Card>
        </div>
      </div>
    </CSSTransition>
  )
}

export const ModalContainer = ({ children, close }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <CSSTransition in appear timeout={0} classNames='modal'>
      <div
        className='modal'
        onClick={e => e.target === e.currentTarget && close()}
      >
        <div className='modal-content'>{children}</div>
      </div>
    </CSSTransition>
  )
}
