import { useFocused, useSelected } from "slate-react";
import { Transforms } from "slate";
import PropTypes from "prop-types";
import { formatTag } from "../utils/functions";
import { Icon } from "components/lib";

/**
 * The TagElement component represents a custom node in the Slate editor that is used for tagging or mentioning.
 * It's a specialized component that displays mentions/tags in a distinct style and allows for interaction
 * such as removal and drag-and-drop functionality. The component uses Slate's hooks to determine if it's
 * selected or focused and applies styles accordingly. It also provides handlers for removing a mention and
 * setting up drag-and-drop functionality.
 *
 * @param {Object} props - Properties passed to the TagElement component.
 * @param {Object} props.attributes - Attributes to apply to the component's root element.
 * @param {Object} props.children - Child nodes of the TagElement component.
 * @param {Object} props.element - Data of the mention element.
 * @param {Object} props.editorRef - Reference to the Slate editor instance.
 * @returns {JSX.Element} A styled span element representing a mention.
 */
export const TagElement = ({ attributes, children, element, editorRef }) => {
    const selected = useSelected();
    const focused = useFocused();

    const removeMention = () => {
        Transforms.removeNodes(editorRef.current, {
            match: n => n === element,
            at: []
        });
    };

    const onDragStart = (e) => {
        e.dataTransfer.setData("mention-id", element.character);
    };

    let style = {
        display: "inline-block",
        whiteSpace: "nowrap",
        borderRadius: "5px",
        margin: "2px",
        background: "#BBDEFB",
        fontWeight: 600,
        fontSize: "14px",
        color: "rgba(0, 0, 0, 0.7)",
        textOverflow: "ellipsis",
        wordWrap: "break-word",
        verticalAlign: "bottom",
        overflow: "hidden",
        transition: "border 0.2s linear",
        cursor: "move",
        padding: '0px 6px',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
    };

    return (
        <div
            {...attributes}
            contentEditable={false}
            draggable
            onDragStart={onDragStart}
            data-cy={`mention-${element.character.replace(' ', '-')}`}
            style={style}
        >
            <div className={'flex gap-1'}>
                {formatTag(element.character)}
                <span className="text-blue-200">|</span>
                <button onClick={removeMention}
                    className={'cursor-pointer text-sm flex justify-center items-center'}>
                    <Icon image='x' size={15} />
                </button>
            </div>
            {children}
        </div>
    );
}

TagElement.propTypes = {
    attributes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    element: PropTypes.object.isRequired,
    editorRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]).isRequired,
};