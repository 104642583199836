import { useRef, useState, useEffect } from "react";
import TagTextEditor from "./components/TagTextEditor.jsx";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { Error } from "components/lib.js";

const parseDataToSlateFormat = (data) => {
    const lines = data.split('\n');
    const paragraphs = lines.map(line => {
        let lineNodes = [];
        let lastIndex = 0;
        const tagRegex = /{([^}]+)}/g;
        let match;
        while ((match = tagRegex.exec(line)) !== null) {
            const tagStart = match.index;
            const tagEnd = tagRegex.lastIndex;
            const tagContent = match[1];

            if (tagStart > lastIndex) {
                lineNodes.push({ text: line.slice(lastIndex, tagStart) });
            }

            lineNodes.push({
                type: 'mention',
                character: tagContent,
                children: [{ text: '' }],
            });

            lineNodes.push({ text: '' });

            lastIndex = tagEnd;
        }

        if (lastIndex < line.length) {
            lineNodes.push({ text: line.slice(lastIndex) });
        }

        return {
            type: 'paragraph',
            children: lineNodes.length > 0 ? lineNodes : [{ text: '' }],
        };
    });

    return paragraphs.length > 0 ? paragraphs : [{ type: 'paragraph', children: [{ text: '' }] }];
};


const serializeSlateData = (nodes) => {
    return nodes.map(node => {
        return node.children.map(child => {
            if (child.type === 'mention') {
                return `{${child.character}}`;
            } else {
                return child.text;
            }
        }).join('');
    }).join('\n').trim();
};


export const useTagTextEditor = (initialValue, tags, maxCharacters = 0, shortCodeReplace) => {
    const [value, setValue] = useState(parseDataToSlateFormat(initialValue));
    const [addedTags, setAddedTags] = useState([]);
    const editorRef = useRef();

    // Effect to update the list of added tags whenever the editor value changes
    useEffect(() => {
        const currentTags = value
            .flatMap(node => node.children)
            .filter(child => child.type === 'mention')
            .map(mention => mention.character);
        setAddedTags(currentTags);
    }, [value]);

    /**
     * Inserts a tag into the editor at the current selection.
     * @param {string} tag - The tag to insert.
     */
    const insertTag = (tag) => {
        if (!editorRef.current) return;
        const editor = editorRef.current;

        const mention = {
            type: 'mention',
            character: tag,
            children: [{ text: '' }],
        };

        // Insert the mention node into the editor
        Transforms.insertNodes(editor, mention);
        Transforms.move(editor);

        // Focus the editor after inserting the tag
        ReactEditor.focus(editor);
    };

    /**
     * Checks if a given tag has already been added to the editor.
     * @param {string} tag - The tag to check.
     * @returns {boolean} - True if the tag is already added, false otherwise.
     */

    const isTagAdded = (tag) => addedTags.includes(tag);

    /**
     * Serializes the editor content into a string format.
     * Converts Slate nodes to a string with special formatting for mentions/tags.
     * @param {Array} nodes - The editor nodes to serialize.
     * @returns {string} - The serialized string representation of the content.
     */
    const serializeContent = (nodes) => {
        return nodes.map(node => {
            if (node.type === 'paragraph') {
                return node.children.map(child => {
                    if (child.text) {
                        return child.text;
                    }

                    if (child.type === 'mention') {
                        return `{${child.character}}`;
                    }

                    return '';
                }).join('');
            }

            return node.children.map(child => serializeContent([child])).join('');
        }).join('\n');
    };

    const element = (props) => {
        const error = props.errorMessage || 'Please enter a value';

        return (
            <div>
                <div
                    className="relative block w-full p-3 rounded bg-white border border-solid border-gray-300 focus:bg-slate-50 appearance-none min-h-32">
                    <TagTextEditor
                        name={props.name}
                        editorRef={editorRef}
                        value={value}
                        setValue={setValue}
                        tags={tags}
                        onBlur={props?.onBlur}
                        direction={props.direction}
                        maxCharacters={props.maxCharacters}
                    />
                </div>
                {props.valid === false && <Error message={error} />}
            </div>
        );
    }

    const serializedContent = serializeContent(value)
    const serializedValue = serializeSlateData(value)
    const trueValue = shortCodeReplace(serializedValue, true)

    const totalChars = trueValue.length;
    const remainingChars = maxCharacters - totalChars;

    return {
        element,
        insertTag,
        isTagAdded,
        serializedContent: serializedContent,
        serializedValue: serializedValue,
        totalChars,
        remainingChars,
        trueValue
    }
}
