/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import { Animate, AuthContext, ViewContext, Button, Form, Card, Link, Row, Message, SocialSignin, useLocation, useNavigate, PasswordInput, TextInput, FormLink } from 'components/lib';
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useRef } from 'react';


export function Signin(props) {

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const qs = location.search;

    if (qs.includes('error')) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=') + 7));
      viewContext.notification.show(msg, 'error');
    }
  }, [location.search, viewContext.notification])

  // state
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  const [captchaRequired, setCaptchaRequired] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const captchaRef = useRef(null);

  const [form, setForm] = useState({
    email: "",
    password: ""
  });
  const [valids, setValids] = useState([])


  const handleInputChange = (name, value, valid) => {
    setForm(prev => ({ ...prev, [name]: value }));

    if (!valid) {
      setValids(prev => [...prev, name])
    } else {
      setValids(prev => prev.filter(p => p !== name))
    }
  }

  const getValid = (name) => {
    return valids.length && !valids.includes(name)
  }

  const mutation = useMutation({
    mutationKey: ['auth'],
    mutationFn: (data) => axios.post('/api/auth', data),
    onSuccess: (res) => {
      if (res.data['2fa_required']) {
        navigate(`/signin/otp?token=${res.data.token}`)
        return
      }

      navigate(authContext.signin(res))
    },
    onError: (err) => {
      const errorData = err?.response?.data;

      if (errorData?.captcha_required) {
        setCaptchaRequired(true);
        captchaRef.current?.reset();
      }

      console.log(errorData)
      if (errorData?.magic_redirect) {
        return window.location.href = errorData.magic_redirect
      }

      viewContext.handleError(err)
    }
  })

  return (
    <Animate type='pop' key={useMagic}>
      <Row title='Sign in to Enzak'>

        {magicLinkSent ?
          <div style={{ width: '28em', margin: '0 auto' }}>
            <Message
              title='Check Your Email'
              type='success'
              text='Please click the link in your email inbox to sign in.'
            />
          </div> :
          <Card restrictWidth center>

            <SocialSignin network={['google']} useMagic={useMagic} showOr />

            {useMagic ?
              <Button
                text='Use password'
                color='blue'
                icon='shield'
                iconColor='blue'
                className='mb-5  gap-3 w-full'
                rounded iconButton small
                action={() => setUseMagic(false)}
              /> :
              <Button
                text='Use magic link instead'
                color='blue'
                icon='star'
                iconColor='blue'
                className='mb-5  gap-3 w-full'
                rounded iconButton small
                action={() => setUseMagic(true)}
              />
            }

            {
              useMagic ? (
                <Form
                  inputs={{
                    email: {
                      label: 'Email',
                      type: 'email',
                      required: true,
                    }
                  }}
                  method='POST'
                  url={'/api/auth/magic'}
                  buttonText={'Send Magic Link'}
                  callback={() => {
                    setMagicLinkSent(true)
                  }}
                />) : (
                <form className='grid'>
                  <TextInput
                    label='Email'
                    name='email'
                    required
                    value={form.email}
                    onChange={handleInputChange}
                    valid={getValid('email')}
                  />
                  <PasswordInput
                    label='Password'
                    name='password'
                    required
                    value={form.password}
                    onChange={handleInputChange}
                    valid={getValid('password')}
                  />
                  {
                    captchaRequired && (
                      <div className='mt-2'>
                        <ReCAPTCHA
                          ref={captchaRef}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                          onChange={value => setCaptcha(value)}
                        />
                      </div>
                    )
                  }
                  <FormLink
                    url={'/forgotpassword'}
                    text={'Forgot your password?'}
                  />
                  <Button
                    text='Sign In'
                    type={'submit'}
                    color={'green'}
                    disabled={valids.length || Object.values(form).some(d => !d) || mutation.isLoading}
                    loading={mutation.isLoading}
                    rounded
                    action={() => mutation.mutate({
                      ...form,
                      captcha
                    })}
                  />
                </form>
              )
            }






            <div className='mt-4'>
              Don't have an account? <Link url='/signup' text='Sign Up' />
            </div>

          </Card>
        }
      </Row>
    </Animate>
  );
}
